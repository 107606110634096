// Generated by Framer (99ae5b3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["eInmg8BJC", "ILEpSET9Q"];

const serializationHash = "framer-hSMVB"

const variantClassNames = {eInmg8BJC: "framer-v-1x7v1ir", ILEpSET9Q: "framer-v-6gn8qf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "eInmg8BJC", "Variant 2": "ILEpSET9Q"}

const getProps = ({height, id, scrollSection, width, ...props}) => { return {...props, UGkbsTndB: scrollSection ?? props.UGkbsTndB, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "eInmg8BJC"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;scrollSection?: React.MutableRefObject<HTMLElement>; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, UGkbsTndB, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "eInmg8BJC", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__styleTransformEffectEnabled __framer__transformTargets={[{target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 0.9, skewX: 0, skewY: 0, x: 0, y: 0}}, {ref: UGkbsTndB, target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}}]} __framer__transformTrigger={"onScrollTarget"} __framer__transformViewportThreshold={0} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1x7v1ir", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"eInmg8BJC"} ref={ref ?? ref1} style={{background: "linear-gradient(180deg, rgb(30, 101, 255) 0%, rgb(25, 97, 255) 100%)", ...style}} {...addPropertyOverrides({ILEpSET9Q: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-f9p5gb"} data-framer-name={"one-side-crop"} layoutDependency={layoutDependency} layoutId={"OENlKfLVe"}><Image background={{alt: "", fit: "fill", intrinsicHeight: 1123, intrinsicWidth: 1104, pixelHeight: 4167, pixelWidth: 4096, sizes: "904px", src: "https://framerusercontent.com/images/FvKRMHebxaumOAtSw0LDcUrffsg.png?scale-down-to=2048", srcSet: "https://framerusercontent.com/images/FvKRMHebxaumOAtSw0LDcUrffsg.png?scale-down-to=1024 1006w,https://framerusercontent.com/images/FvKRMHebxaumOAtSw0LDcUrffsg.png?scale-down-to=2048 2013w,https://framerusercontent.com/images/FvKRMHebxaumOAtSw0LDcUrffsg.png?scale-down-to=4096 4026w,https://framerusercontent.com/images/FvKRMHebxaumOAtSw0LDcUrffsg.png 4096w"}} className={"framer-6x8ee6"} data-framer-name={"app-image"} layoutDependency={layoutDependency} layoutId={"a_0FWj4Z3"}/></motion.div></MotionDivWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hSMVB.framer-1c4nw4s, .framer-hSMVB .framer-1c4nw4s { display: block; }", ".framer-hSMVB.framer-1x7v1ir { height: 574px; overflow: visible; position: relative; width: 1040px; }", ".framer-hSMVB .framer-f9p5gb { bottom: 0px; flex: none; height: 776px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-hSMVB .framer-6x8ee6 { bottom: -216px; flex: none; height: 888px; left: calc(50.00000000000002% - 904px / 2); overflow: visible; position: absolute; width: 904px; }", ".framer-hSMVB.framer-v-6gn8qf.framer-1x7v1ir { height: 802px; }", ".framer-hSMVB.framer-v-6gn8qf .framer-f9p5gb { height: 980px; }", ".framer-hSMVB.framer-v-6gn8qf .framer-6x8ee6 { bottom: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 574
 * @framerIntrinsicWidth 1040
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ILEpSET9Q":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"UGkbsTndB":"scrollSection"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQPKwa3HxE: React.ComponentType<Props> = withCSS(Component, css, "framer-hSMVB") as typeof Component;
export default FramerQPKwa3HxE;

FramerQPKwa3HxE.displayName = "color-container";

FramerQPKwa3HxE.defaultProps = {height: 574, width: 1040};

addPropertyControls(FramerQPKwa3HxE, {variant: {options: ["eInmg8BJC", "ILEpSET9Q"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, UGkbsTndB: {title: "Scroll Section", type: ControlType.ScrollSectionRef}} as any)

addFonts(FramerQPKwa3HxE, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})